import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.isImmediate && this.isEffective) {
      this.show()
    }
  }

  disconnect() {
    this.dispose()
  }

  show(event) {
    if (event) {
      event.preventDefault()
    }
    $(this.element).modal('show')
    return this.appendPostprocesses(
      new Promise((resolve, reject) => {
        $(this.element).one('hidden.bs.modal', (e) => resolve(e))
      }),
      event
    )
  }

  showIfEffective(event) {
    if (event) {
      event.preventDefault()
    }
    if (this.isEffective) {
      return this.show(event)
    } else {
      return this.appendPostprocesses(Promise.resolve(), event)
    }
  }

  appendPostprocesses(promise, event) {
    return promise
      .then(() => {
        if (event && event.detail && typeof event.detail.after === 'function') {
          return event.detail.after()
        }
      })
      .then(() => {
        if (this.redirecting) {
          window.location.href = this.redirecting
        }
      })
  }

  hide(event) {
    if (event) {
      event.preventDefault()
    }
    $(this.element).modal('hide')
  }

  dispose() {
    $(this.element).modal('dispose')
  }

  onChangeEffectiveness(event) {
    this.isEffective = !event.target.checked
  }

  get name() {
    return this.data.get('name')
  }

  get isImmediate() {
    return this.data.has('immediate')
  }

  get isEffective() {
    return !this.data.has('ineffective') && localStorage.getItem(`${this.name}-effective`) !== '0'
  }

  set isEffective(value) {
    localStorage.setItem(`${this.name}-effective`, value ? '1' : '0')
  }

  get redirecting() {
    return this.data.get('redirecting')
  }
}
