import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cell']

  connect() {
    const initialIndex = this.cellTargets.findIndex(elem => elem.classList.contains('slick-cell-self'))
    $(this.element).slick({
      arrows: true,
      initialSlide: initialIndex,
      slidesToShow: 5, // TODO: プロジェクト数が増えたら 7 に増やす
      centerMode: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            swipe: true,
            swipeToSlide: true
          }
        }
      ]
    })
  }
}
