import ModalController from './modal_controller'

export default class extends ModalController {
  static targets = ['lastContent']
  connect() {
    super.connect()
    $(this.element).one('shown.bs.modal', (e) => {
      if (this.hasLastContentTarget) {
        this.lastContentTarget.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }
  get isEffective() {
    return true
  }
}
