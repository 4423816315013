import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'count' ]
  static values = { count: Number }

  incrementCount() {
    this.countValue++
  }

  countValueChanged() {
    this.updateCount()
  }

  updateCount() {
    this.countTarget.textContent = this.countValue
  }
}
