import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (window.installPromptEvent) {
      this.element.hidden = false;
    } else {
      // connect したときにまだ beforeinstallprompt が来ていない場合に備えて、ここでも addEventListener する
      window.addEventListener('beforeinstallprompt', this.prepareA2hs);
    }
  }

  disconnect() {
    window.removeEventListener('beforeinstallprompt', this.prepareA2hs);
  }

  prepareA2hs = (event) => {
    this.element.hidden = false;
    window.installPromptEvent = event;
  }

  promptInstall() {
    window.installPromptEvent.prompt();
  }
}
