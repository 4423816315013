import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'recurringTab',
    'recurringFiveHundred',
    'recurringFiveHundredRadioButton',
    'recurringAmount',
    'oneshotAmount',
    'alertMessage',
  ]
  static values = { recurringOnlySponsorIds: Array }

  connect() {
    const params = new URLSearchParams(location.search)
    const sponsorId = parseInt(params.get('sponsor_id'), 10)
    this.toggleAmount(sponsorId)
  }

  onSelectSponsorRadioButton(event) {
    const sponsorId = parseInt(event.target.value, 10)
    this.toggleAmount(sponsorId)
  }

  toggleAmount(sponsorId) {
    // スポンサーIDは15で決め打ち
    const SPECIAL_SPONSOR_ID = 15
    if (sponsorId === SPECIAL_SPONSOR_ID) {
      this.recurringTabTarget.click()
      this.recurringFiveHundredRadioButtonTarget.click()
      const amounts = this.getAmountsExceptRecurringFiveHundred()
      this.hideAmounts(amounts)
      this.showAlertMessage()
    } else if (this.recurringOnlySponsorIdsValue.includes(sponsorId)) {
      this.showAmounts(this.recurringAmountTargets)
      this.hideAmounts(this.oneshotAmountTargets)
      this.showAlertMessage()
    } else {
      const amounts = this.getAmountsExceptRecurringFiveHundred()
      this.showAmounts(amounts)
      this.hideAlertMessage()
    }
  }

  getAmountsExceptRecurringFiveHundred() {
    return this.recurringAmountTargets
      .concat(this.oneshotAmountTargets)
      .filter((amount) => amount !== this.recurringFiveHundredTarget)
  }

  showAmounts(amounts) {
    amounts.forEach((amount) => {
      amount.hidden = false
    })
  }

  hideAmounts(amounts) {
    amounts.forEach((amount) => {
      amount.hidden = true
      amount.classList.remove('active')
    })
  }

  showAlertMessage() {
    this.alertMessageTarget.hidden = false
  }

  hideAlertMessage() {
    this.alertMessageTarget.hidden = true
  }
}
