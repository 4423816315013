import $ from 'jquery';
import 'slick-carousel';
import 'readmore-js';

window.$ = $;
window.jQuery = $;

document.addEventListener('DOMContentLoaded', () => {
  const navProjects = document.querySelector('.projects-navigation__hidden');
  // プロジェクトナビゲーションがあるページのみ適用する
  if (navProjects) {
    navProjects.classList.remove('projects-navigation__hidden');
  }
});
