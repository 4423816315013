import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  connect() {
    $(this.element)
      .carousel({
        pause: false,
      })
      .carousel('cycle')
    // TODO: ランダムに変える
  }

  disconnect() {
    $(this.element).carousel('dispose')
  }
}
