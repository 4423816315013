import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['lastContent']
  connect() {
    $(this.element).on('shown.bs.modal', (e) => {
      if (this.hasLastContentTarget) {
        this.lastContentTarget.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }
}
