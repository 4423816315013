import ModalController from './modal_controller'

export default class extends ModalController {
  static values = {signedIn: Boolean, lastMessageAt: Number}

  connect() {
    super.connect();
    $(this.element).one('hidden.bs.modal', (e) => {
      this.isEffective = this.lastMessageAtValue
    })
  }

  get isEffective() {
    const lastMessageAt = localStorage.getItem('guest-notification-modal-last-message-at')
    return !this.signedInValue && (lastMessageAt === null || this.lastMessageAtValue > parseInt(lastMessageAt))
  }

  set isEffective(value) {
    localStorage.setItem('guest-notification-modal-last-message-at', value)
  }
}
