import { Controller } from '@hotwired/stimulus'
import { useMeta } from 'stimulus-use'
import { initFincode, getCardToken } from '@fincode/js'
export default class extends Controller {
  static targets = ['cardError']
  static values = {
    customerId: String
  }
  static metaNames = ['rails_env', 'fincode_public_key']

  get isProduction() {
    return this.railsEnvMeta === 'production'
  }

  connect() {
    useMeta(this)
    this.setupForm()
  }

  async setupForm() {
    this.fincode = await initFincode({
      publicKey: this.fincodePublicKeyMeta,
      isLiveMode: this.isProduction,
    })

    this.ui = this.fincode.ui({ layout: 'vertical' })
    this.ui.create('token', {
      layout: 'vertical',
      colorPlaceHolder: '#000000', // 指定しないとf9f9f9になって背景と同じで見えなくなる
    })
    this.ui.mount('fincode', '320')
  }

  async getFincodeCardToken() {
    try {
      const response = await getCardToken({
        fincode: this.fincode,
        ui: this.ui
      })
      return response ? response.list[0].token : null
    }
    catch (error) {
      this.cardErrorTarget.textContent = error.errors.map(a => a.error_message).join('')
      return null
    }
  }
}
