import StripeController from './stripe_controller'

export default class extends StripeController {
  static targets = ['form', 'paymentMethodIdField', 'cardholderNameField']

  async onFormSubmit(event) {
    event.preventDefault()

    const {setupIntent, error} = await this.stripe.confirmCardSetup(
      this.clientSecret,
      {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.cardholderNameFieldTarget.value,
          },
        },
      }
    )

    if (error) {
      this.cardErrorTarget.textContent = error.message
      return
    }
    this.cardErrorTarget.textContent = ''

    this.setPaymentMethodAndSubmit(setupIntent.payment_method)
  }

  setPaymentMethodAndSubmit(paymentMethodId) {
    this.paymentMethodIdFieldTarget.setAttribute('value', paymentMethodId)
    this.formTarget.submit()
  }

  get clientSecret() {
    return this.data.get('secret')
  }

}
