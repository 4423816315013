import ModalController from './modal_controller'
import { trackCustomEvent } from '../services/gtm'

export default class extends ModalController {
  static values = { question: String, answer: String, uuid: String }

  connect() {
    super.connect()

    $(this.element).one('shown.bs.modal', (e) => {
      trackCustomEvent('enquete.modal.action', {
        modal_action: 'open',
        'enquete-uuid': this.uuidValue,
      })
    })
    $(this.element).one('hidden.bs.modal', (e) => {
      trackCustomEvent('enquete.modal.action', {
        modal_action: 'close',
        'enquete-uuid': this.uuidValue,
      })
    })
  }

  submitEnquete(event) {
    trackCustomEvent('enquete', {
      'enquete-version': '2023-09-17',
      'enquete-question': this.questionValue,
      'enquete-answer': this.answerValue,
      'enquete-uuid': this.uuidValue,
    })
  }

  get isEffective() {
    return true
  }
}
