import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['icon', 'form']
  static values = {unreadCount: Number}

  connect() {
    this.updateBadge()
  }

  clickTitle(event) {
    console.log(event)
    const form = this.formTargets.find((f) => f.getAttribute('action') === event.target.dataset.url)
    if (form) {
      Rails.fire(form, 'submit')
    }
  }

  onPostMarkAsReadAll(event) {
    // 再読み込みで既読状態を反映（未読のものだけ更新したいところ）
    window.location.reload()
  }

  unreadCountValueChanged() {
    this.updateBadge()
  }

  updateBadge() {
    this.iconTarget.setAttribute('data-count', this.unreadCountValue)
  }
}
