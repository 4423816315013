import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cardInput', 'cardError']
  static values = { publishableKey: String }

  setupForm() {
    this.stripe = Stripe(this.publishableKeyValue, { locale: 'ja' })
    this.stripeElements = this.stripe.elements()
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        // 値が不正のときに文字色を変える
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }
    this.card = this.stripeElements.create('card', {
      style,
      hidePostalCode: true,
      disableLink: true,
    })
    this.card.mount(this.cardInputTarget)

    this.card.addEventListener('change', (event) => {
      const displayError = this.cardErrorTarget
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })
  }
}
