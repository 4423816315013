import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  onChange(event) {
    const projectId = event.target.value
    this.buttonTarget.href = `/projects/${projectId}/bulk_send_room_messages/new`
  }
}
