import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['range', 'count']

  connect() {
    this.onChange()
  }

  onChange() {
    if (this.rangeTarget.value === 'none') {
      this.countTarget.disabled = true
      this.countTarget.classList.remove('visible')
      this.countTarget.classList.add('invisible')
    } else {
      this.countTarget.disabled = this.countAlwaysDisabled()
      this.countTarget.classList.remove('invisible')
      this.countTarget.classList.add('visible')
    }
  }

  countAlwaysDisabled() {
    return this.data.has('countAlwaysDisabled')
  }
}
