import { Controller } from '@hotwired/stimulus'
import HttpStatus from 'http-status-codes'
import insertAdditional from './insert_additional'
import { trackCustomEvent } from '../services/gtm'
import { VisitorSession } from '../lib/visitorSession'

export default class extends Controller {
  static targets = [
    'button',
    'tnPhoto',
    'photo',
    'balloon',
    'userIcon',
    'todayCount',
    'monthCount',
    'totalCount',
    'todayDonationAmount',
    'monthDonationAmount',
    'totalDonationAmount',
    'projectsNavigation',
    'clickUserIcon',
  ]
  static values = {
    todayCount: Number,
    monthCount: Number,
    totalCount: Number,
    // NOTE: 画面右下のハート数表示（ heart_controller の countValue ）と同じ。二重管理になる
    countForStacked: Number,
    userId: Number,
    todayDonationAmount: Number,
    monthDonationAmount: Number,
    totalDonationAmount: Number,
    amountPerClick: Number,
    unreadActivityId: Number,
  }

  initialize() {
    this.formatter = new Intl.NumberFormat('ja-JP')
  }

  connect() {
    const state = this.data.get('state')
    if (state === 'after') {
      this.alreadyClicked()
    }
  }

  afterClick(animation = true) {
    const button = this.buttonTarget
    const balloon = this.balloonTarget
    const projectsNavigation = this.projectsNavigationTarget
    button.classList.remove('page-visual__btn--fluffy')
    if (animation) {
      const buttonTransition = () => {
        // 未読の活動報告がある場合は、その活動報告までスクロールする
        // ない場合はグローバルナビゲーションまでスクロールする
        const elActivity =
          this.unreadActivityIdValue !== 0
            ? document.getElementById(`activity-${this.unreadActivityIdValue}`)
            : undefined
        if (elActivity) {
          elActivity.scrollIntoView({ behavior: 'smooth' })
        } else {
          const compStyles = window.getComputedStyle(projectsNavigation)
          const top =
            projectsNavigation.getBoundingClientRect().top +
            window.pageYOffset -
            parseInt(compStyles.getPropertyValue('margin-top'))
          window.scroll({ top: top })
        }
        if (typeof animation === 'function') {
          animation()
        }
        button.removeEventListener('transitionend', buttonTransition)
      }
      button.addEventListener('transitionend', buttonTransition)
      button.classList.add('page-visual__btn--animation')
    } else {
      button.classList.add('page-visual__btn--animated')
    }
    this.tnPhotoTargets.forEach((tnPhoto) => {
      tnPhoto.classList.remove('img-gray-dark')
    })
    const photo = this.photoTarget
    photo.classList.remove('img-gray')
    photo.classList.add('img-colorful-animation')
    balloon.classList.remove('d-none')
    this.data.set('state', 'after')
  }

  alreadyClicked() {
    this.disableButton()
    this.afterClick(false)
  }

  incrementCount() {
    this.todayCountValue++
    this.monthCountValue++
    this.totalCountValue++
    this.countForStackedValue++
    this.todayDonationAmountValue += this.amountPerClickValue
    this.monthDonationAmountValue += this.amountPerClickValue
    this.totalDonationAmountValue += this.amountPerClickValue

    this.dispatch('incrementCount')
  }

  updateCount() {
    this.todayCountTargets.forEach((todayCount) => {
      todayCount.textContent = this.formatter.format(this.todayCountValue)
    })
    this.monthCountTargets.forEach((monthCount) => {
      monthCount.textContent = this.formatter.format(this.monthCountValue)
    })
    this.totalCountTargets.forEach((totalCount) => {
      totalCount.textContent = this.formatter.format(this.totalCountValue)
    })
    this.todayDonationAmountTargets.forEach((today) => {
      today.textContent = this.formatter.format(this.todayDonationAmountValue)
    })
    this.monthDonationAmountTargets.forEach((month) => {
      month.textContent = this.formatter.format(this.monthDonationAmountValue)
    })
    this.totalDonationAmountTargets.forEach((total) => {
      total.textContent = this.formatter.format(this.totalDonationAmountValue)
    })
    this.dispatch('updateCount')
  }

  disableButton() {
    this.buttonTarget.disabled = true
  }

  onPostSuccess(event) {
    const [data, _status, _xhr] = event.detail

    this.afterClick(() => {
      insertAdditional(data, document.querySelector('aside#additional'))
    })
    this.addClickUserIcon()
    this.incrementCount()
    this.updateCount()
    trackCustomEvent('heart_click')

    // トータル5回クリックした場合のみ通知許可を促す
    const isSupported = OneSignal && OneSignal.Notifications.isPushSupported()
    if (isSupported && this.countForStackedValue === 5) {
      trackCustomEvent('onesignal.notification', { type: 'show_prompt' })
      OneSignal.Slidedown.promptPush()
    }
  }

  onPostError(event) {
    const statusCode = event.detail[2].status
    // TODO: エラーメッセージを表示したい
    if (statusCode === HttpStatus.TOO_MANY_REQUESTS) {
      window.location.reload()
    }
  }

  addClickUserIcon() {
    this.clickUserIconTarget.hidden = false
  }
}
