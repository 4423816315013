import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input', 'newIcon', 'currentIcon' ]

  show() {
    this.newIcon = this.input
  }

  get input() {
    return this.inputTarget.files ? this.inputTarget.files[0] : null
  }

  set newIcon(file) {
    file ? this.showNewIconPreview(file) : this.showCurrentIconPreview()
  }

  showNewIconPreview(file) {
    const newIcon = this.newIconTarget
    const reader = new FileReader
    reader.onload = function(e) {
      newIcon.src = e.currentTarget.result
    }
    this.setCurrentIconHidden(true)
    reader.readAsDataURL(file)
    newIcon.hidden = false
  }

  showCurrentIconPreview() {
    const newIcon = this.newIconTarget
    newIcon.hidden = true
    newIcon.src = ''
    this.setCurrentIconHidden(false)
  }

  setCurrentIconHidden(hidden) {
    if (this.hasCurrentIconTarget) {
      this.currentIconTarget.hidden = hidden
    }
  }
}
