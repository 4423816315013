import { Controller } from '@hotwired/stimulus'
import insertAdditional from './insert_additional'

export default class extends Controller {
  static targets = ['aside', 'errorMessage']

  onSuccess(event) {
    console.log(event)
    this.clearErrorMessage()
    const [data, _status, _xhr] = event.detail
    insertAdditional(data, this.asideTarget)
  }

  onFailure(event) {
    console.error(event)
    const [data, _status, _xhr] = event.detail
    this.errorMessage = data
  }

  set errorMessage(text) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.textContent = text
    }
  }

  clearErrorMessage() {
    this.errorMessage = ''
  }
}
