import FincodeController from './fincode_controller'
import { Modal } from 'bootstrap'

export default class extends FincodeController {
  static targets = [
    'form',
    'modal',
    'paymentAddressField',
    'searchedAddressField',
    'donationReceiptAgreement',
    'paymentButton',
    'fincodeTokenField',
  ]

  modalTargetConnected() {
    this.modal = new Modal(this.modalTarget)
    this.modal.show()
    this.modalTarget.addEventListener('hidden.bs.modal', (event) => {
      this.element.remove()
    })
  }

  donationReceiptAgreementTargetConnected() {
    this.updateDonationReceiptAgreement()
  }
  searchedAddressFieldTargetConnected() {
    new YubinBango.MicroformatDom()
  }

  onChangeCardExisting() {
    this.data.set('method', 'existing_card')
  }

  onChangeCardNew() {
    this.data.set('method', 'new_card')
  }

  updateDonationReceiptAgreement() {
    if (this.hasPaymentButtonTarget) {
      this.paymentButtonTarget.disabled =
        !this.donationReceiptAgreementTarget.checked
    }
  }

  fillAddress(event) {
    this.paymentAddressFieldTarget.value = this.searchedAddressFieldTarget.value
    this.paymentAddressFieldTarget.focus()
  }

  async onFormSubmit(event) {
    event.preventDefault()

    if (this.data.get('method') === 'existing_card') {
      this.formTarget.submit()
      return
    }
    const token = await this.getFincodeCardToken()
    if (token) {
      this.fincodeTokenFieldTarget.value = token
      this.formTarget.submit()
    }
  }

  close() {
    this.modal && this.modal.hide()
  }
}
